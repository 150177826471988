<script>
import Header from '@/components/productPreview/Header.vue'
import Footer from '@/components/productPreview/Footer.vue'
import axios from 'axios'
import * as enpointConfig from '../../../utils/endpoints'

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      product: {
        price: {},
        images: [],
        properties: [
          {
            description: '',
          },
        ],
      },
      blob: {
        url: `${enpointConfig.AZURE_BLOB_URL}`,
      },
    }
  },
  mounted() {
    axios
      .get(
        `${enpointConfig.STORE_URL}/product/slug/${this.$route.params.slug}/preview/${this.$route.params.region}`,
      )
      .then(response => {
        this.product = response.data
      })
      .catch(error => {})
  },

}
</script>

<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <link
      rel="stylesheet"
      href="/product_preview/css/style1.css"
    >
    <link
      rel="stylesheet"
      href="/product_preview/css/style2.css"
    >
    <link
      rel="stylesheet"
      href="/product_preview/css/style3.css"
      media="print"
      onload="this.media='all'; this.onload=null;"
    >
    <link
      rel="stylesheet"
      href="/product_preview/css/custom_style.css"
    >
    <div>
      <div hastransition="true">
        <Header />
        <div class="asset-detail-page">
          <main class="main-layout">
            <section class="back-link my-20px">
              <a
                aria-current="page"
                href="#"
                class="router-link-active router-link-exact-active"
              ><img
                :src="`https://${ blob.url }/pwc-upload/assets/icons/arrow-left.svg`"
                alt="back-arrow"
              ><span>Back</span></a>
            </section>
            <section
              class="asset-background pt-40px pl-35px"
              :style="{
                backgroundImage: `url('${
                  product.images.length > 0
                    ? product.images.find(e => e.type === 'header_image').link
                    : 'https://' + blob.url + '/pwc-upload/assets/images/Tax_GettyImages-167890481-scaled.jpg'}')`,
              }"
            >
              <div class="background-title">
                <h3>{{ product.title }}</h3>
              </div>
              <!-- <div class="badge-container mt-8px">
                <p class="asset-type-badge badge-tech">
                  PwC Tech
                </p>
              </div> -->
            </section>
            <div
              class="ant-row main-content"
              style="margin-left: -8px; margin-right: -8px"
            >
              <div
                class="ant-col ant-col-12"
                style="padding-left: 8px; padding-right: 8px"
              >
                <div
                  class="asset-content mt-40px"
                  v-html="product.properties[0].description"
                />
              </div>
              <div
                class="ant-col ant-col-12"
                style="padding-left: 8px; padding-right: 8px"
              >
                <div class="more-info">
                  <div class="product-utility">
                    <div class="package-price">
                      <span>Price</span><span>MYR {{ product.price.from_price }}</span>
                    </div>
                    <div class="utility mt-80px">
                      <div class="left-part">
                        <button
                          class="ant-btn mr-20px"
                          type="button"
                          style="margin-right: 20px"
                        >
                          <svg
                            class="svg-inline--fa fa-heart fa-icon"
                            style=""
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="far"
                            data-icon="heart"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path
                              class=""
                              style=""
                              fill="currentColor"
                              d="M244 84L255.1 96L267.1 84.02C300.6 51.37 347 36.51 392.6 44.1C461.5 55.58 512 115.2 512 185.1V190.9C512 232.4 494.8 272.1 464.4 300.4L283.7 469.1C276.2 476.1 266.3 480 256 480C245.7 480 235.8 476.1 228.3 469.1L47.59 300.4C17.23 272.1 0 232.4 0 190.9V185.1C0 115.2 50.52 55.58 119.4 44.1C164.1 36.51 211.4 51.37 244 84C243.1 84 244 84.01 244 84L244 84zM255.1 163.9L210.1 117.1C188.4 96.28 157.6 86.4 127.3 91.44C81.55 99.07 48 138.7 48 185.1V190.9C48 219.1 59.71 246.1 80.34 265.3L256 429.3L431.7 265.3C452.3 246.1 464 219.1 464 190.9V185.1C464 138.7 430.4 99.07 384.7 91.44C354.4 86.4 323.6 96.28 301.9 117.1L255.1 163.9z"
                            />
                          </svg></button><button
                          class="ant-btn"
                          type="button"
                        >
                          <img
                            :src="`https://${ blob.url }/pwc-upload/assets/icons/share-nodes.svg`"
                            alt="share-icon"
                          >
                        </button>
                      </div>
                      <div class="right-part">
                        <button
                          class="ant-btn"
                          type="button"
                        >
                          <img
                            :src="`https://${ blob.url }/pwc-upload/assets/icons/black-cart.svg`"
                            alt="cart-icon"
                          ><span>Add to Cart</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="solution-contact">
                    <h2>Need a customised solution?</h2>
                    <button
                      class="ant-btn ant-btn-primary"
                      type="button"
                    >
                      <span>Contact us</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <section class="related-products">
              <div
                class="ant-divider ant-divider-horizontal"
                role="separator"
              />
              <h2>Other products people are interested in</h2>
              <div class="related-product-container">
                <div class="product-catalogue-container">
                  <div
                    class="ant-row product-catalogue-layout"
                    style="
                      margin-left: -16px;
                      margin-right: -16px;
                      margin-top: -16px;
                      margin-bottom: -16px;
                    "
                  >
                    <div
                      class="ant-col ant-col-xs-24 ant-col-md-8 product-column-wrapper"
                      style="
                        padding-left: 16px;
                        padding-right: 16px;
                        padding-top: 16px;
                        padding-bottom: 16px;
                      "
                    >
                      <div class="product-container">
                        <div class="product-image-section">
                          <a
                            aria-current="page"
                            href="#"
                            class="router-link-active router-link-exact-active"
                          ><img
                            :src="`https://${ blob.url }/pwc-upload/assets/images/Tax_GettyImages-167890481-scaled.jpg`"
                            alt="Annual income tax return filing - an employee&#39;s perspective"
                          ></a>
                          <div class="product-overlay" />
                          <div class="product-title">
                            <a
                              aria-current="page"
                              href="#"
                              class="router-link-active router-link-exact-active"
                            >Annual income tax return filing - an
                              employee&#39;s perspective</a>
                            <p class="asset-type-badge badge-academy">
                              Academy
                            </p>
                          </div>
                          <div class="product-heart">
                            <svg
                              class="svg-inline--fa fa-heart"
                              style=""
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="far"
                              data-icon="heart"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path
                                class=""
                                style=""
                                fill="currentColor"
                                d="M244 84L255.1 96L267.1 84.02C300.6 51.37 347 36.51 392.6 44.1C461.5 55.58 512 115.2 512 185.1V190.9C512 232.4 494.8 272.1 464.4 300.4L283.7 469.1C276.2 476.1 266.3 480 256 480C245.7 480 235.8 476.1 228.3 469.1L47.59 300.4C17.23 272.1 0 232.4 0 190.9V185.1C0 115.2 50.52 55.58 119.4 44.1C164.1 36.51 211.4 51.37 244 84C243.1 84 244 84.01 244 84L244 84zM255.1 163.9L210.1 117.1C188.4 96.28 157.6 86.4 127.3 91.44C81.55 99.07 48 138.7 48 185.1V190.9C48 219.1 59.71 246.1 80.34 265.3L256 429.3L431.7 265.3C452.3 246.1 464 219.1 464 190.9V185.1C464 138.7 430.4 99.07 384.7 91.44C354.4 86.4 323.6 96.28 301.9 117.1L255.1 163.9z"
                              />
                            </svg>
                          </div>
                          <div class="from">
                            FROM <br>
                            MYR
                          </div>
                        </div>
                        <div class="product-description">
                          Under the Self-Assessment System (SAS), greater
                          responsibility lies with the taxpayer to understand
                          the tax law, determine the correct tax...
                        </div>
                      </div>
                    </div>
                    <div
                      class="ant-col ant-col-xs-24 ant-col-md-8 product-column-wrapper"
                      style="
                        padding-left: 16px;
                        padding-right: 16px;
                        padding-top: 16px;
                        padding-bottom: 16px;
                      "
                    >
                      <div class="product-container">
                        <div class="product-image-section">
                          <a
                            aria-current="page"
                            href="#"
                            class="router-link-active router-link-exact-active"
                          ><img
                            :src="`https://${ blob.url }/pwc-upload/assets/images/Process-Mining_GettyImages-1086741384-scaled.jpg`"
                            alt="Process Mining"
                          ></a>
                          <div class="product-overlay" />
                          <div class="product-title">
                            <a
                              aria-current="page"
                              href="#"
                              class="router-link-active router-link-exact-active"
                            >Process Mining</a>
                            <p class="asset-type-badge badge-tech">
                              PwC Tech
                            </p>
                          </div>
                          <div class="product-heart">
                            <svg
                              class="svg-inline--fa fa-heart"
                              style=""
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="far"
                              data-icon="heart"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path
                                class=""
                                style=""
                                fill="currentColor"
                                d="M244 84L255.1 96L267.1 84.02C300.6 51.37 347 36.51 392.6 44.1C461.5 55.58 512 115.2 512 185.1V190.9C512 232.4 494.8 272.1 464.4 300.4L283.7 469.1C276.2 476.1 266.3 480 256 480C245.7 480 235.8 476.1 228.3 469.1L47.59 300.4C17.23 272.1 0 232.4 0 190.9V185.1C0 115.2 50.52 55.58 119.4 44.1C164.1 36.51 211.4 51.37 244 84C243.1 84 244 84.01 244 84L244 84zM255.1 163.9L210.1 117.1C188.4 96.28 157.6 86.4 127.3 91.44C81.55 99.07 48 138.7 48 185.1V190.9C48 219.1 59.71 246.1 80.34 265.3L256 429.3L431.7 265.3C452.3 246.1 464 219.1 464 190.9V185.1C464 138.7 430.4 99.07 384.7 91.44C354.4 86.4 323.6 96.28 301.9 117.1L255.1 163.9z"
                              />
                            </svg>
                          </div>
                          <div class="from">
                            FROM <br>
                            MYR
                          </div>
                        </div>
                        <div class="product-description">
                          Process mining is an approach to analyse business
                          processes based on event logs collected from the
                          company&#39;s underlying source systems.…
                        </div>
                      </div>
                    </div>
                    <div
                      class="ant-col ant-col-xs-24 ant-col-md-8 product-column-wrapper"
                      style="
                        padding-left: 16px;
                        padding-right: 16px;
                        padding-top: 16px;
                        padding-bottom: 16px;
                      "
                    >
                      <div class="product-container">
                        <div class="product-image-section">
                          <a
                            aria-current="page"
                            href="#"
                            class="router-link-active router-link-exact-active"
                          ><img
                            :src="`https://${ blob.url }/pwc-upload/assets/images/Robotic-Process-Automation-RPA_GettyImages-962630204-1-scaled.jpeg`"
                            alt="Robotic Process Automation (RPA)"
                          ></a>
                          <div class="product-overlay" />
                          <div class="product-title">
                            <a
                              aria-current="page"
                              href="#"
                              class="router-link-active router-link-exact-active"
                            >Robotic Process Automation (RPA)</a>
                            <p class="asset-type-badge badge-tech">
                              PwC Tech
                            </p>
                          </div>
                          <div class="product-heart">
                            <svg
                              class="svg-inline--fa fa-heart"
                              style=""
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="far"
                              data-icon="heart"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path
                                class=""
                                style=""
                                fill="currentColor"
                                d="M244 84L255.1 96L267.1 84.02C300.6 51.37 347 36.51 392.6 44.1C461.5 55.58 512 115.2 512 185.1V190.9C512 232.4 494.8 272.1 464.4 300.4L283.7 469.1C276.2 476.1 266.3 480 256 480C245.7 480 235.8 476.1 228.3 469.1L47.59 300.4C17.23 272.1 0 232.4 0 190.9V185.1C0 115.2 50.52 55.58 119.4 44.1C164.1 36.51 211.4 51.37 244 84C243.1 84 244 84.01 244 84L244 84zM255.1 163.9L210.1 117.1C188.4 96.28 157.6 86.4 127.3 91.44C81.55 99.07 48 138.7 48 185.1V190.9C48 219.1 59.71 246.1 80.34 265.3L256 429.3L431.7 265.3C452.3 246.1 464 219.1 464 190.9V185.1C464 138.7 430.4 99.07 384.7 91.44C354.4 86.4 323.6 96.28 301.9 117.1L255.1 163.9z"
                              />
                            </svg>
                          </div>
                          <div class="from">
                            FROM <br>
                            MYR
                          </div>
                        </div>
                        <div class="product-description">
                          RPA is the application of enabling technologies to
                          create a “robot” to operate existing software
                          applications and ‘mimic’ the interaction…
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </div>

        <Footer />
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.asset-detail-page {
  .main-content {
    .asset-content {
      padding: 10px;

      h1 {
        color: #000000;
        font-family: "Helvetica Neue Normal";
        font-size: 24px;
        font-weight: bold;
        line-height: 28px;
      }

      ul {
        li::before {
          content: "\25aa";
          color: red;
          display: inline-block;
          width: 0.9em;
          margin-left: -1em;
          font-size: 1.5em;
        }
      }

      p img {
        display: inline-block;
      }
    }
  }
}
</style>
